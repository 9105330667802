"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetectionProfileExport = exports.DetectionPlateDetector = exports.DetectionProfileAllowedDirection = exports.ResizeFit = exports.ClusterMessageType = exports.MicroserviceStatus = exports.MicroserviceMode = exports.MicroserviceType = exports.DbType = exports.ActionType = exports.LogLevel = exports.LogAction = exports.ApplicationType = exports.UserStatus = exports.RoleType = exports.OS = exports.FileType = exports.DeviceRequestType = exports.TableType = exports.DeviceDoorStatus = exports.LogType = exports.Resource = exports.ServerType = exports.ZoneShape = exports.DeviceEnabledType = exports.TrashRepStatus = exports.CargoType = exports.Direction = exports.ZoneScheduleDays = exports.AlertType = exports.DeviceWorkStatusFilter = exports.DeviceVerificationFilter = exports.VehicleColor = exports.ZoneType = void 0;
var ZoneType;
(function (ZoneType) {
    ZoneType[ZoneType["NONE"] = 0] = "NONE";
    ZoneType[ZoneType["LANE"] = 1] = "LANE";
    ZoneType[ZoneType["SIDEWALK"] = 2] = "SIDEWALK";
    ZoneType[ZoneType["SHOULDER"] = 3] = "SHOULDER";
    ZoneType[ZoneType["RAILS"] = 4] = "RAILS";
    ZoneType[ZoneType["STOP_LINE"] = 5] = "STOP_LINE";
    ZoneType[ZoneType["AFTER_STOP_LINE"] = 6] = "AFTER_STOP_LINE";
    ZoneType[ZoneType["STOP_LIGHT"] = 7] = "STOP_LIGHT";
    ZoneType[ZoneType["ARROW_LIGHT"] = 8] = "ARROW_LIGHT";
    ZoneType[ZoneType["DELIMETER"] = 9] = "DELIMETER";
    ZoneType[ZoneType["DOUBLE_DELIMETER"] = 10] = "DOUBLE_DELIMETER";
    ZoneType[ZoneType["ARROW_STOP_LINE"] = 11] = "ARROW_STOP_LINE";
    ZoneType[ZoneType["COMMUNAL_LANE"] = 12] = "COMMUNAL_LANE";
    ZoneType[ZoneType["ONE_WAY"] = 13] = "ONE_WAY";
    ZoneType[ZoneType["TRUCK"] = 14] = "TRUCK";
    ZoneType[ZoneType["CALIBRATION"] = 15] = "CALIBRATION";
    ZoneType[ZoneType["ZONE_ONCOMING_DRIVE"] = 18] = "ZONE_ONCOMING_DRIVE";
    ZoneType[ZoneType["ARROW_STOP_LINE_STRAIGHT"] = 19] = "ARROW_STOP_LINE_STRAIGHT";
    ZoneType[ZoneType["TRUCK_STAT"] = 20] = "TRUCK_STAT";
    ZoneType[ZoneType["INTEREST"] = 21] = "INTEREST";
    ZoneType[ZoneType["MOVING"] = 22] = "MOVING";
    ZoneType[ZoneType["INTERVAL_MEASURE"] = 23] = "INTERVAL_MEASURE";
    ZoneType[ZoneType["BUS"] = 24] = "BUS";
    ZoneType[ZoneType["WAFFLE"] = 25] = "WAFFLE";
    ZoneType[ZoneType["INTERVAL_MEASURE_CAR"] = 26] = "INTERVAL_MEASURE_CAR";
    ZoneType[ZoneType["INTERVAL_MEASURE_TRUCK"] = 27] = "INTERVAL_MEASURE_TRUCK";
    ZoneType[ZoneType["INTERVAL_MEASURE_BUS"] = 28] = "INTERVAL_MEASURE_BUS";
    ZoneType[ZoneType["CROSSWALK_UNCONTROLLED"] = 29] = "CROSSWALK_UNCONTROLLED";
    ZoneType[ZoneType["BELT"] = 30] = "BELT";
    ZoneType[ZoneType["RADAR"] = 31] = "RADAR";
    ZoneType[ZoneType["ILLEGAL_PARKING"] = 32] = "ILLEGAL_PARKING";
    ZoneType[ZoneType["PLACE_TRASH"] = 33] = "PLACE_TRASH";
    ZoneType[ZoneType["LOW_QUALITY_TRASH_REMOVAL"] = 34] = "LOW_QUALITY_TRASH_REMOVAL";
    ZoneType[ZoneType["OUTSIDE_CONTAINER_TRASH"] = 35] = "OUTSIDE_CONTAINER_TRASH";
})(ZoneType = exports.ZoneType || (exports.ZoneType = {}));
var VehicleColor;
(function (VehicleColor) {
    VehicleColor[VehicleColor["UNKNOWN"] = 0] = "UNKNOWN";
    VehicleColor[VehicleColor["BLACK"] = 1] = "BLACK";
    VehicleColor[VehicleColor["WHITE"] = 2] = "WHITE";
    VehicleColor[VehicleColor["GRAY"] = 3] = "GRAY";
    VehicleColor[VehicleColor["GREEN"] = 4] = "GREEN";
    VehicleColor[VehicleColor["LIGHT_BLUE"] = 5] = "LIGHT_BLUE";
    VehicleColor[VehicleColor["DARK_BLUE"] = 6] = "DARK_BLUE";
    VehicleColor[VehicleColor["RED"] = 7] = "RED";
    VehicleColor[VehicleColor["YELLOW"] = 8] = "YELLOW";
    VehicleColor[VehicleColor["VIOLET"] = 9] = "VIOLET";
    VehicleColor[VehicleColor["BROWN"] = 10] = "BROWN";
    VehicleColor[VehicleColor["ORANGE"] = 11] = "ORANGE";
})(VehicleColor = exports.VehicleColor || (exports.VehicleColor = {}));
var DeviceVerificationFilter;
(function (DeviceVerificationFilter) {
    DeviceVerificationFilter[DeviceVerificationFilter["NONE"] = 1] = "NONE";
    DeviceVerificationFilter[DeviceVerificationFilter["EXPIRED"] = 2] = "EXPIRED";
    DeviceVerificationFilter[DeviceVerificationFilter["EXPIRED_IN_4MONTHS_OR_LESS"] = 3] = "EXPIRED_IN_4MONTHS_OR_LESS";
    DeviceVerificationFilter[DeviceVerificationFilter["OK"] = 4] = "OK";
    DeviceVerificationFilter[DeviceVerificationFilter["HAS"] = 5] = "HAS";
})(DeviceVerificationFilter = exports.DeviceVerificationFilter || (exports.DeviceVerificationFilter = {}));
var DeviceWorkStatusFilter;
(function (DeviceWorkStatusFilter) {
    DeviceWorkStatusFilter[DeviceWorkStatusFilter["NOT_WORKING"] = 1] = "NOT_WORKING";
    DeviceWorkStatusFilter[DeviceWorkStatusFilter["WORKING"] = 2] = "WORKING";
    DeviceWorkStatusFilter[DeviceWorkStatusFilter["ERRORS"] = 3] = "ERRORS";
    DeviceWorkStatusFilter[DeviceWorkStatusFilter["NO_REPORTS_1HOUR_OR_MORE"] = 4] = "NO_REPORTS_1HOUR_OR_MORE";
    DeviceWorkStatusFilter[DeviceWorkStatusFilter["NO_REPORTS_24HOUR_OR_MORE"] = 5] = "NO_REPORTS_24HOUR_OR_MORE";
})(DeviceWorkStatusFilter = exports.DeviceWorkStatusFilter || (exports.DeviceWorkStatusFilter = {}));
var AlertType;
(function (AlertType) {
    AlertType[AlertType["NONE"] = 0] = "NONE";
    AlertType[AlertType["CENTRAL"] = 1] = "CENTRAL";
    AlertType[AlertType["DEVICE"] = 2] = "DEVICE";
    AlertType[AlertType["VEHICLE"] = 3] = "VEHICLE";
    AlertType[AlertType["DOOR"] = 4] = "DOOR";
})(AlertType = exports.AlertType || (exports.AlertType = {}));
var ZoneScheduleDays;
(function (ZoneScheduleDays) {
    ZoneScheduleDays[ZoneScheduleDays["WORKING_DAYS"] = -1] = "WORKING_DAYS";
    ZoneScheduleDays[ZoneScheduleDays["DAY_OFFS"] = -2] = "DAY_OFFS";
    ZoneScheduleDays[ZoneScheduleDays["EVERY_DAY"] = 0] = "EVERY_DAY";
    ZoneScheduleDays[ZoneScheduleDays["MONDAY"] = 1] = "MONDAY";
    ZoneScheduleDays[ZoneScheduleDays["TUESDAY"] = 2] = "TUESDAY";
    ZoneScheduleDays[ZoneScheduleDays["WEDNESDAY"] = 3] = "WEDNESDAY";
    ZoneScheduleDays[ZoneScheduleDays["THURSDAY"] = 4] = "THURSDAY";
    ZoneScheduleDays[ZoneScheduleDays["FRIDAY"] = 5] = "FRIDAY";
    ZoneScheduleDays[ZoneScheduleDays["SATURDAY"] = 6] = "SATURDAY";
    ZoneScheduleDays[ZoneScheduleDays["SUNDAY"] = 7] = "SUNDAY";
})(ZoneScheduleDays = exports.ZoneScheduleDays || (exports.ZoneScheduleDays = {}));
var Direction;
(function (Direction) {
    Direction[Direction["NONE"] = 0] = "NONE";
    Direction[Direction["ONCOMING"] = 1] = "ONCOMING";
    Direction[Direction["PASSING"] = 2] = "PASSING";
})(Direction = exports.Direction || (exports.Direction = {}));
var CargoType;
(function (CargoType) {
    CargoType[CargoType["UNKNOWN"] = 0] = "UNKNOWN";
    CargoType[CargoType["CARGO"] = 1] = "CARGO";
    CargoType[CargoType["EMPTY"] = 2] = "EMPTY";
    CargoType[CargoType["TRASH"] = 3] = "TRASH";
})(CargoType = exports.CargoType || (exports.CargoType = {}));
var TrashRepStatus;
(function (TrashRepStatus) {
    TrashRepStatus[TrashRepStatus["NONE"] = 0] = "NONE";
    TrashRepStatus[TrashRepStatus["EVERYDAY"] = 1] = "EVERYDAY";
    TrashRepStatus[TrashRepStatus["CLEAR2DIRTY"] = 2] = "CLEAR2DIRTY";
    TrashRepStatus[TrashRepStatus["DIRTY2CLEAR"] = 3] = "DIRTY2CLEAR";
})(TrashRepStatus = exports.TrashRepStatus || (exports.TrashRepStatus = {}));
var DeviceEnabledType;
(function (DeviceEnabledType) {
    DeviceEnabledType[DeviceEnabledType["PROFILE"] = -1] = "PROFILE";
    DeviceEnabledType[DeviceEnabledType["DISABLED"] = 0] = "DISABLED";
    DeviceEnabledType[DeviceEnabledType["ENABLED"] = 1] = "ENABLED";
    DeviceEnabledType[DeviceEnabledType["MAINTENANCE"] = 2] = "MAINTENANCE";
})(DeviceEnabledType = exports.DeviceEnabledType || (exports.DeviceEnabledType = {}));
var ZoneShape;
(function (ZoneShape) {
    ZoneShape["LINE"] = "line";
    ZoneShape["RECT"] = "rect";
    ZoneShape["QUADR"] = "quadr";
})(ZoneShape = exports.ZoneShape || (exports.ZoneShape = {}));
var ServerType;
(function (ServerType) {
    ServerType["PUBLIC"] = "public";
    ServerType["PRIVATE"] = "private";
    ServerType["SOCKET"] = "socket";
})(ServerType = exports.ServerType || (exports.ServerType = {}));
var Resource;
(function (Resource) {
    Resource["DEVICES"] = "devices";
    Resource["USERS"] = "users";
    Resource["DETECTION_PROFILES"] = "detectionProfiles";
    Resource["NEURO_NETS"] = "neuroNets";
    Resource["VEHICLE_LOGS"] = "vehicleLogs";
    Resource["ZONES"] = "zones";
    Resource["PROFILE"] = "profile";
    Resource["LOGS"] = "logs";
    Resource["COMMUNALS"] = "communals";
    Resource["ALERTS"] = "alerts";
    Resource["STATISTICS"] = "statistics";
    Resource["VERIFICATIONS"] = "verifications";
})(Resource = exports.Resource || (exports.Resource = {}));
var LogType;
(function (LogType) {
    LogType[LogType["NONE"] = 0] = "NONE";
    LogType[LogType["USER_LOGIN"] = 1] = "USER_LOGIN";
    LogType[LogType["START_CENTRAL"] = 2] = "START_CENTRAL";
    LogType[LogType["STOP_CENTRAL"] = 3] = "STOP_CENTRAL";
    LogType[LogType["DEVICE_CONNECT"] = 4] = "DEVICE_CONNECT";
    LogType[LogType["DEVICE_DISCONNECT"] = 5] = "DEVICE_DISCONNECT";
    LogType[LogType["ADD_DEVICE"] = 6] = "ADD_DEVICE";
    LogType[LogType["EDIT_DEVICE"] = 7] = "EDIT_DEVICE";
    LogType[LogType["DELETE_DEVICE"] = 8] = "DELETE_DEVICE";
    LogType[LogType["ADD_DETECTION_PROFILE"] = 9] = "ADD_DETECTION_PROFILE";
    LogType[LogType["EDIT_DETECTION_PROFILE"] = 10] = "EDIT_DETECTION_PROFILE";
    LogType[LogType["DELETE_DETECTION_PROFILE"] = 11] = "DELETE_DETECTION_PROFILE";
    LogType[LogType["ADD_USER"] = 12] = "ADD_USER";
    LogType[LogType["EDIT_USER"] = 13] = "EDIT_USER";
    LogType[LogType["DELETE_USER"] = 14] = "DELETE_USER";
    LogType[LogType["ADD_PERMISSION"] = 15] = "ADD_PERMISSION";
    LogType[LogType["DELETE_PERMISSION"] = 16] = "DELETE_PERMISSION";
    LogType[LogType["USER_LOGOUT"] = 17] = "USER_LOGOUT";
    LogType[LogType["ADD_ALERT"] = 18] = "ADD_ALERT";
    LogType[LogType["EDIT_ALERT"] = 19] = "EDIT_ALERT";
    LogType[LogType["DELETE_ALERT"] = 20] = "DELETE_ALERT";
    LogType[LogType["FOUND_VEHICLE"] = 21] = "FOUND_VEHICLE";
    LogType[LogType["ADD_COMMUNAL"] = 22] = "ADD_COMMUNAL";
    LogType[LogType["DELETE_COMMUNAL"] = 23] = "DELETE_COMMUNAL";
    LogType[LogType["ADD_NN"] = 24] = "ADD_NN";
    LogType[LogType["EDIT_NN"] = 25] = "EDIT_NN";
    LogType[LogType["DELETE_NN"] = 26] = "DELETE_NN";
    LogType[LogType["DOOR_OPENED"] = 27] = "DOOR_OPENED";
    LogType[LogType["DOOR_CLOSED"] = 28] = "DOOR_CLOSED";
    LogType[LogType["DEVICE_CONNECT_AFTER_POWER_LOSS"] = 29] = "DEVICE_CONNECT_AFTER_POWER_LOSS";
    LogType[LogType["DEVICE_CONNECT_AFTER_CONNECTION_LOSS"] = 30] = "DEVICE_CONNECT_AFTER_CONNECTION_LOSS";
    LogType[LogType["LESS_AVERAGE_DETECTS"] = 31] = "LESS_AVERAGE_DETECTS";
    LogType[LogType["ADD_ZONE"] = 32] = "ADD_ZONE";
    LogType[LogType["EDIT_ZONE"] = 33] = "EDIT_ZONE";
    LogType[LogType["DELETE_ZONE"] = 34] = "DELETE_ZONE";
})(LogType = exports.LogType || (exports.LogType = {}));
var DeviceDoorStatus;
(function (DeviceDoorStatus) {
    DeviceDoorStatus[DeviceDoorStatus["CLOSED"] = 0] = "CLOSED";
    DeviceDoorStatus[DeviceDoorStatus["OPENED"] = 1] = "OPENED";
})(DeviceDoorStatus = exports.DeviceDoorStatus || (exports.DeviceDoorStatus = {}));
var TableType;
(function (TableType) {
    TableType["DEVICE"] = "device";
    TableType["STATUS"] = "status";
    TableType["DETECTION_PROFILE"] = "detection_profile";
    TableType["ZONE"] = "zone";
    TableType["VEHICLE_LOG"] = "vehicle_log";
    TableType["NEURO_NET"] = "neuronet";
    TableType["PERMISSION"] = "permission";
    TableType["USER"] = "user";
    TableType["LOG"] = "log";
    TableType["REQUEST"] = "request";
    TableType["COMMUNAL"] = "communal";
    TableType["ALERT"] = "alert";
    TableType["OPERATOR"] = "operator";
    TableType["STATISTIC"] = "statistic";
    TableType["REFRESH_TOKEN"] = "refresh_token";
    TableType["MICROSERVICE"] = "microservice";
    TableType["FILE"] = "file";
    TableType["MIGRATION"] = "migration";
})(TableType = exports.TableType || (exports.TableType = {}));
var DeviceRequestType;
(function (DeviceRequestType) {
    DeviceRequestType[DeviceRequestType["VIDEO"] = 0] = "VIDEO";
    DeviceRequestType[DeviceRequestType["IMAGE"] = 1] = "IMAGE";
    DeviceRequestType[DeviceRequestType["CONFIG"] = 2] = "CONFIG";
    DeviceRequestType[DeviceRequestType["COMMUNAL_LIST"] = 3] = "COMMUNAL_LIST";
    DeviceRequestType[DeviceRequestType["GPS_LOG"] = 4] = "GPS_LOG";
    DeviceRequestType[DeviceRequestType["DISTANCE"] = 5] = "DISTANCE";
    DeviceRequestType[DeviceRequestType["VEHICLE"] = 6] = "VEHICLE";
    DeviceRequestType[DeviceRequestType["DEVICE_LOG"] = 7] = "DEVICE_LOG";
    DeviceRequestType[DeviceRequestType["HIGH_QUALITY_IMAGE"] = 12] = "HIGH_QUALITY_IMAGE";
})(DeviceRequestType = exports.DeviceRequestType || (exports.DeviceRequestType = {}));
var FileType;
(function (FileType) {
    FileType["IMAGE"] = "image";
    FileType["ICO"] = "ico";
    FileType["SRT"] = "srt";
    FileType["ZIP"] = "zip";
})(FileType = exports.FileType || (exports.FileType = {}));
var OS;
(function (OS) {
    OS["MOBILE_ANDROID"] = "mobileAndroid";
    OS["TV_ANDROID"] = "tvAndroid";
    OS["IOS"] = "ios";
    OS["TIZEN"] = "tizen";
    OS["WEBOS"] = "webos";
    OS["ROKU"] = "roku";
    OS["BROWSER"] = "browser";
})(OS = exports.OS || (exports.OS = {}));
var RoleType;
(function (RoleType) {
    RoleType["OPERATOR"] = "operator";
    RoleType["ADMINISTRATOR"] = "administrator";
    RoleType["VIEWER"] = "viewer";
})(RoleType = exports.RoleType || (exports.RoleType = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["BANNED"] = 0] = "BANNED";
    UserStatus[UserStatus["ACTIVE"] = 1] = "ACTIVE";
    UserStatus[UserStatus["CONFIRMATION"] = 2] = "CONFIRMATION";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var ApplicationType;
(function (ApplicationType) {
    ApplicationType["DASHBOARD"] = "dashboard";
})(ApplicationType = exports.ApplicationType || (exports.ApplicationType = {}));
var LogAction;
(function (LogAction) {
    LogAction["LOGIN"] = "login";
    LogAction["LOGOUT"] = "logout";
    LogAction["UPDATE"] = "update";
    LogAction["CREATE"] = "create";
    LogAction["DELETE"] = "delete";
})(LogAction = exports.LogAction || (exports.LogAction = {}));
var LogLevel;
(function (LogLevel) {
    LogLevel["INFO"] = "info";
    LogLevel["WARNING"] = "warning";
    LogLevel["ERROR"] = "error";
    LogLevel["DEBUG"] = "debug";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "update";
    ActionType["CREATE"] = "create";
    ActionType["DELETE"] = "delete";
    ActionType["LIST"] = "list";
    ActionType["GET"] = "get";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var DbType;
(function (DbType) {
    DbType["DASHBOARD"] = "dashboard";
    DbType["CONSOLE"] = "console";
    DbType["MIGRATIONS"] = "migrations";
})(DbType = exports.DbType || (exports.DbType = {}));
var MicroserviceType;
(function (MicroserviceType) {
    MicroserviceType["DASHBOARD"] = "dashboard";
    MicroserviceType["WORKER"] = "worker";
    MicroserviceType["MESSENGER"] = "messenger";
    MicroserviceType["CONSOLE"] = "console";
})(MicroserviceType = exports.MicroserviceType || (exports.MicroserviceType = {}));
var MicroserviceMode;
(function (MicroserviceMode) {
    MicroserviceMode["REGULAR"] = "regular";
    MicroserviceMode["CRON"] = "cron";
})(MicroserviceMode = exports.MicroserviceMode || (exports.MicroserviceMode = {}));
var MicroserviceStatus;
(function (MicroserviceStatus) {
    MicroserviceStatus["ONLINE"] = "online";
    MicroserviceStatus["OFFLINE"] = "offline";
})(MicroserviceStatus = exports.MicroserviceStatus || (exports.MicroserviceStatus = {}));
var ClusterMessageType;
(function (ClusterMessageType) {
    ClusterMessageType["HOOK"] = "hook";
    ClusterMessageType["GET_SHARED_USER_THREAD"] = "getSharedUserThread";
    ClusterMessageType["SET_SHARED_USER_THREAD"] = "setSharedUserThread";
    ClusterMessageType["RESPONSE_SHARED_USER_THREAD"] = "responseSharedUserThread";
    ClusterMessageType["REQUEST"] = "request";
    ClusterMessageType["RESPONSE"] = "response";
    ClusterMessageType["RESPONSE_CATCH"] = "responseCatch";
    ClusterMessageType["READY"] = "ready";
    ClusterMessageType["ADD_TRACKING_MODEL"] = "addTrackingModel";
})(ClusterMessageType = exports.ClusterMessageType || (exports.ClusterMessageType = {}));
var ResizeFit;
(function (ResizeFit) {
    ResizeFit["COVER"] = "cover";
    ResizeFit["CONTAIN"] = "contain";
    ResizeFit["FILL"] = "fill";
    ResizeFit["INSIDE"] = "inside";
    ResizeFit["OUTSIDE"] = "outside";
})(ResizeFit = exports.ResizeFit || (exports.ResizeFit = {}));
var DetectionProfileAllowedDirection;
(function (DetectionProfileAllowedDirection) {
    DetectionProfileAllowedDirection[DetectionProfileAllowedDirection["ALL"] = 0] = "ALL";
    DetectionProfileAllowedDirection[DetectionProfileAllowedDirection["OPPOSITE"] = 1] = "OPPOSITE";
    DetectionProfileAllowedDirection[DetectionProfileAllowedDirection["PASSING"] = 2] = "PASSING";
})(DetectionProfileAllowedDirection = exports.DetectionProfileAllowedDirection || (exports.DetectionProfileAllowedDirection = {}));
var DetectionPlateDetector;
(function (DetectionPlateDetector) {
    DetectionPlateDetector[DetectionPlateDetector["DEFAULT"] = 0] = "DEFAULT";
    DetectionPlateDetector[DetectionPlateDetector["DP_ROADAR"] = 1] = "DP_ROADAR";
})(DetectionPlateDetector = exports.DetectionPlateDetector || (exports.DetectionPlateDetector = {}));
var DetectionProfileExport;
(function (DetectionProfileExport) {
    DetectionProfileExport[DetectionProfileExport["NONE"] = 0] = "NONE";
    DetectionProfileExport[DetectionProfileExport["ANGEL"] = 1] = "ANGEL";
})(DetectionProfileExport = exports.DetectionProfileExport || (exports.DetectionProfileExport = {}));
